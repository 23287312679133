<template>
  <div class="container">
    <PublicHeader totalName="Message" :noLeft="true" />
    <van-pull-refresh v-model="refreshing" :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText" :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded" @refresh="onRefresh">
      <van-list class="list-box" ref="list" v-model="loading" :finished="finished"
        :loading-text="$t('public').loadingText" :finished-text="$t('public').noMoreData" @load="getList">
        <div class="item" v-for="(lItem, lIndex) in list" :key="lIndex">
          <div class="top">
            <span class="type">{{ lItem.content }}</span>
          </div>
          <!-- <div class="bottom">
            <span>{{ lItem.CreateTime }}</span>
          </div> -->
        </div>
      </van-list>
    </van-pull-refresh>
    <van-dialog v-model="show" title="详情">
      <div class="details">{{ Remark }}</div>
    </van-dialog>
    <TabBar active="message" />
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { balanceChangeTypeArr, TransferStatusArr } from '@/utils/validate'
import { mapGetters } from 'vuex'
import TabBar from '@/components/tabBar'
export default {
  components: {
    PublicHeader,
    TabBar,
  },
  data() {
    return {
      balanceChangeTypeArr,
      TransferStatusArr,
      Remark: null,
      show: false,
      list: [],
      form: {
        page: 1,
        size: 10,
      },
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    async getList() {
     //全部消息已读
      await userApi.upUserMessageRead()
      const res = await userApi.getUserMessageList(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }

      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align center
  .list-box
    height calc(100vh - 50px)
    overflow scroll
  .item
    padding 5px 10px
    font-size 13px
    line-height 1.5
    color #747269
    .top, .bottom
      display flex
      justify-content space-between
      .type, .money
        font-weight bold
        font-size 15px
        color #a79d71
    .status
      color #ff976a
    .fail
      color #ff0000
    .success
      color #fae397
    .Remark
      text-align left
      color #ced1d6
  .van-dialog
    background #1F1F1F
    padding 0 10px
    font-size 13px
    color #fff
    >>> .van-dialog__header
      font-size 16px
      color #ebe5cd
      padding-top 12px
    >>> .van-dialog__content
      padding 5px
    >>> .van-button--default
      background #1F1F1F
      color #ebe5cd!important
    .details
      text-align left
      line-height 1.5
      padding 7.5px 0
.down
  color #31ad91 !important
</style>
